import { NavbarConfig } from "@energylab/shared-components/constants/config/navbarConfig";

export const navbarConfig: NavbarConfig = {
    fullWidth: false,
    navbarItems: [
        {
            id: "events",
            translationKey: "menuItem.events",
            redirectTo: { path: "events" }
        },
        {
            id: "gallery",
            translationKey: "menuItem.gallery",
            redirectTo: { path: "gallery" }
        },
        {
            id: "challenges",
            translationKey: "menuItem.challenges",
            redirectTo: { path: "challenges" }
        },
        {
            id: "about",
            translationKey: "menuItem.about",
            redirectTo: { path: "about" }
        },
        {
            id: "benefits",
            translationKey: "menuItem.benefits",
            redirectTo: {
                path: "benefits"
            }
        },
        {
            id: "news",
            translationKey: "menuItem.news",
            redirectTo: { path: "news" }
        },
        {
            id: "energyPoints",
            translationKey: "menuItem.energyPoints",
            redirectTo: {
                path: "energy-points"
            }
        },
        {
            id: "webshop",
            translationKey: "menuItem.webshop",
            redirectTo: {
                path: "webshop"
            }
        },
        {
            id: "archive",
            translationKey: "menuItem.archive",
            redirectTo: {
                path: "archive"
            }
        }
        // {
        //     id: "ambassadors",
        //     translationKey: "menuItem.ambassadors",
        //     redirectTo: {
        //         path: "ambassadors"
        //     }
        // },
    ]
};
