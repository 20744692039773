import { lazy } from "react";
import { RedirectType } from "@energylab/shared-components/routes/routes";
const ContentContainer = lazy(() => import("@energylab/shared-components/modules/pages/content/contentContainer"));

export const ROUTES = [
    {
        path: "archive",
        element: <ContentContainer type="archive" />
    },
    {
        path: "energy-points",
        element: <ContentContainer type="energypoint" />
    }
];

export const REDIRECTS: RedirectType[] = [];
