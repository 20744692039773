import { DashboardPlacesConfig, dashboardPlacesConfig } from "@energylab/shared-components/constants/config/dashboardPlacesConfig";

export const defaultDashboardPlacesConfig: DashboardPlacesConfig = {
    ...dashboardPlacesConfig,
    homePage: ["dashboard", "dashboard_homepage"],
    ambassadors: ["ambassador_banner"],
    events: ["events_banner"],
    benefits: ["benefits_banner"],
    challenges: ["challenge_banner"],
    gallery: ["gallery_banner"],
    articles: ["articles_banner"]
};
