import { FormatMessage } from "@energylab/shared-components/components/formatMessage/formatMessage";
import { nationalities } from "@energylab/shared-components/constants/nationalities";
import { FormElement } from "@energylab/shared-components/components/UI/form/formBuilderManager/formBuilderManager";
import * as React from "react";

export const nationality: FormElement = {
    title: "form.nationality",
    type: "select",
    options: nationalities.map(nat => (
        {
            title: <FormatMessage tagName="span" id={`nationality.${nat.code}`} />,
            value: nat.code
        }
    )),
    property: "nationality",
    required: true
};
