import { ProfileConfig } from "@energylab/shared-components/constants/config/profileConfig";
import { profileStructure } from "../profileStructure";

export const profileConfig: ProfileConfig = {
    requiredProfileFields: [
        "givenName",
        "familyName",
        "birthDate",
        "sex",
        "nationality",
        "location"
    ],
    structure: profileStructure
};
