import { SeoConfig } from "@energylab/shared-components/constants/config/seoConfig";

export const seo: SeoConfig = {
    pageTitle: "Energy @ Deme",
    title: {
        nl: "Energy @ Deme",
        fr: "Energy @ Deme",
        en: "Energy @ Deme"
    },
    description: {
        nl: "",
        fr: "",
        en: ""
    },
    image: "",
    site: ""
};
