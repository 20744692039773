import { FormStructureScheme } from "@energylab/shared-components/components/UI/form/formBuilderManager/types";
import { compareToFirstPasswordCreator } from "@energylab/shared-components/utils/compareToFirstPassword";
import * as React from "react";
import * as environment from "environment";
import { FormatMessage } from "@energylab/shared-components/components/formatMessage/formatMessage";
import { nationality } from "./nationality";
import { sex } from "./sex";
import { location } from "./location";
import { checkMinBirthDateCreator } from "@energylab/shared-components/utils/checkMinBirthDate";
import { motivation } from "./motivation";

export const registerStructure: FormStructureScheme = {
    type: "steps",
    steps: [
        {
            title: "register.profilePoliciesStepTitle",
            status: "current",
            icon: "",
            formElements: []
        },
        {
            title: "register.basicStepTitle",
            status: "current",
            icon: "",
            formElements: [
                {
                    title: "form.email",
                    type: "text",
                    property: "email",
                    required: true,
                    validationRules: [{
                        type: "email",
                        message: <FormatMessage id="auth.error.invalidEmail" />
                    }]
                },
                {
                    title: "form.password",
                    type: "password",
                    property: "password",
                    validationRules: [
                        { min: 8, message: <FormatMessage id="auth.error.minLength" values={{ length: 8 }} /> },
                        { pattern: /\d/, message: <FormatMessage id="auth.error.numberRequired" /> },
                        { pattern: /[A-Z]/, message: <FormatMessage id="auth.error.uppercaseRequired" /> },
                        { pattern: /[a-z]/, message: <FormatMessage id="auth.error.lowercaseRequired" /> },
                        { pattern: /[a-zA-Z]/, message: <FormatMessage id="auth.error.letterRequired" /> }
                    ],
                    required: true
                },
                {
                    title: "register.passwordInfo",
                    type: "p",
                    property: "passwordInfo",
                    hide: true
                },
                {
                    title: "form.confirmPassword",
                    type: "password",
                    property: "passwordConfirm",
                    validationRules: [
                        {
                            validatorBuilder: compareToFirstPasswordCreator,
                            message: <FormatMessage id="auth.error.notEqualPassword" />
                        }
                    ],
                    required: true
                }
            ]
        },
        {
            title: "register.profileStepTitle",
            status: "current",
            icon: "",
            formElements: [
                {
                    title: "form.givenName",
                    type: "text",
                    property: "givenName",
                    required: true
                },
                {
                    title: "form.familyName",
                    type: "text",
                    property: "familyName",
                    required: true
                },
                {
                    title: "form.sex",
                    type: "radio",
                    options: sex,
                    property: "sex",
                    required: true
                },
                {
                    title: "form.birthDate",
                    type: "date",
                    property: "birthDate",
                    required: true,
                    validationRules: [
                        {
                            validatorBuilder: checkMinBirthDateCreator(environment.minYearOld)
                        }
                    ]
                },
                nationality,
                location,
                motivation
            ]
        },
        {
            title: "register.profileStepExtraTitle",
            status: "current",
            icon: "",
            formElements: [
                {
                    title: "form.avatar",
                    type: "uploadAvatar",
                    property: "avatar"
                }
            ]
        }
    ]
};
