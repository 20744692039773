export const manualActivityConfig = {
    calculateDistanceAndStepsForExtraActivities: true,
    extraActivities: [
        {
            value: "badminton",
            labelId: "badminton",
            conversionRate: 131,
            calHour: 588 * 1000
        },
        {
            value: "basketball",
            labelId: "basketball",
            conversionRate: 130,
            calHour: 515.549999979 * 1000
        },
        {
            value: "circuitTraining",
            labelId: "circuitTraining",
            conversionRate: 199,
            calHour: 407.925 * 1000
        },
        {
            value: "climbing",
            labelId: "climbing",
            conversionRate: 270,
            calHour: 483.2625 * 1000
        },
        {
            value: "cricket",
            labelId: "Cricket",
            conversionRate: 42,
            calHour: 307.23 * 1000
        },
        {
            value: "dancing",
            labelId: "Dancing",
            conversionRate: 72,
            calHour: 483.0583333 * 1000
        },
        {
            value: "dogWalking",
            labelId: "dogWalking",
            conversionRate: 84,
            calHour: 257.25 * 1000
        },
        {
            value: "fieldHockey",
            labelId: "fieldHockey",
            conversionRate: 8,
            calHour: 475 * 1000
        },
        {
            value: "gardening",
            labelId: "gardening",
            conversionRate: 89,
            calHour: 327.699056601 * 1000
        },
        {
            value: "golf",
            labelId: "golf",
            conversionRate: 109,
            calHour: 307.23 * 1000
        },
        {
            value: "horsebackRiding",
            labelId: "horsebackRiding",
            conversionRate: 90,
            calHour: 404.25 * 1000
        },
        {
            value: "judoAndKarate",
            labelId: "judoAndKarate",
            conversionRate: 236,
            calHour: 573.3 * 1000
        },
        {
            value: "pilates",
            labelId: "pilates",
            conversionRate: 91,
            calHour: 216.825 * 1000
        },
        {
            value: "rowing",
            labelId: "rowing",
            conversionRate: 212,
            calHour: 563.01 * 1000
        },
        {
            value: "sailing",
            labelId: "sailing",
            conversionRate: 91,
            calHour: 516 * 1000
        },
        {
            value: "shopping",
            labelId: "shopping",
            conversionRate: 67,
            calHour: 183.75 * 1000
        },
        {
            value: "skating",
            labelId: "skating",
            conversionRate: 102,
            calHour: 482 * 1000
        },
        {
            value: "shovelingSnow",
            labelId: "shovelingSnow",
            conversionRate: 145,
            calHour: 446 * 1000
        },
        {
            value: "skiing",
            labelId: "skiing",
            conversionRate: 109,
            calHour: 514.5 * 1000
        },
        {
            value: "snowboarding",
            labelId: "snowboarding",
            conversionRate: 182,
            calHour: 514.5 * 1000
        },
        {
            value: "soccer",
            labelId: "soccer",
            conversionRate: 181,
            calHour: 624.75 * 1000
        },
        {
            value: "stairClimbing",
            labelId: "stairClimbing",
            conversionRate: 181,
            calHour: 294 * 1000
        },
        {
            value: "surf",
            labelId: "surf",
            conversionRate: 91,
            calHour: 516 * 1000
        },
        {
            value: "windsurf",
            labelId: "windsurf",
            conversionRate: 91,
            calHour: 516 * 1000
        },
        {
            value: "kitesurf",
            labelId: "kitesurf",
            conversionRate: 91,
            calHour: 516 * 1000
        },
        {
            value: "tennis",
            labelId: "tennis",
            conversionRate: 200,
            calHour: 588 * 1000
        },
        {
            value: "volleyball",
            labelId: "volleyball",
            conversionRate: 91,
            calHour: 385.875 * 1000
        },
        {
            value: "weightlifting",
            labelId: "weightlifting",
            conversionRate: 67,
            calHour: 441 * 1000
        },
        {
            value: "yoga",
            labelId: "yoga",
            conversionRate: 45,
            calHour: 216.825 * 1000
        }
    ]
};
