import { FormatMessage } from "@energylab/shared-components/components/formatMessage/formatMessage";
import { FormElement } from "@energylab/shared-components/components/UI/form/formBuilderManager/formBuilderManager";
import * as React from "react";

export const location: FormElement = {
    title: "form.location",
    type: "select",
    options: ["projectsite", "office", "vessel"].map(value => (
        {
            title: <FormatMessage tagName="span" id={`location.${value}`} />,
            value
        }
    )),
    property: "location",
    required: true
};
